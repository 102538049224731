import React, { useState, useEffect } from "react"
import Img from "gatsby-image"
import { Link, useStaticQuery, graphql } from "gatsby"
import { AiOutlinePlus } from "react-icons/ai"

import "../sass/hamburgers.scss"
import "./component.scss"
import styled from "styled-components"
import { ActionButton } from "../components/buttons"



interface HeaderContainerProps {
  open: boolean
}

const HEADER_COLOR = "#444444"
const LINK_COLOR = "white"
const HEADER_HEIGHT = "200px"

const HeaderContainer = styled.div<HeaderContainerProps>`
    
  top: 0;
  width: 100%;  
  width: -webkit-fill-available;
  transition: height 0.2s linear;
  overflow: visible;
  @media all and (max-width: 670px) {
    .mobile-hide {display: none;}
  }
  @media all and (min-width: 671px) {
    .mobile-show {display: none;}
  }
`

const HeaderLogo = styled.div`
  
  margin-left: 20px;
  margin-right: 20px;
`

const HeaderContact = styled.div`
  
  width: 100%;
  background-color: black;
`

const ContactDetails = styled.div`

  ul {
    padding: 0 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    margin: 0;
  }
  a, li {
    color: white;
    text-decoration: none;
    list-style: none;
    font-size: 15px;
      }
  li {    
    margin-left: 2rem;
  }
  .material-icons {
    font-size: 18px;
    transform: translatey(3px);
    margin-right: 5px;
  }
  
`

const HeaderMain = styled.div`
  
  background-color: ${HEADER_COLOR};
  height: calc(${HEADER_HEIGHT} - 67px);
  display: flex;
  align-items: center;
  z-index: 10;
  padding: 10px 20px;
`

const HeaderContent = styled.div`
  
  max-width: 1600px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  @media all and (max-width: 400px) {
    flex-wrap: wrap;
    justify-content: center;
  }
`

const HeaderMenu = styled.div`
  
  width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  button {
    margin-left: 2rem;
    @media all and (max-width: 1280px) {
      margin-left: 1rem;
    }
  }

`

const LinkWrapper = styled.div`
  @media only screen and (max-width: 980px) {
    display: none;
  }
  @media all and (max-width: 1360px) {
    font-size: 15px;
  }
  @media all and (max-width: 1280px) {
    li a {
      margin: 0.2rem;
    }
  }
  @media all and (max-width: 1250px) {
    max-width: 470px;
    .link-container li a {
      padding: 0.5rem;
    }
  }
  
  font-size: 18px;  
  a {
    text-decoration: none;
  }
  ul {
    list-style: none;
    margin: 0;
    padding-left: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
  }

  li {
    color: ${LINK_COLOR};
    display: block;
    float: left;
    position: relative;
    text-decoration: none;
  }

  li a {
    display: block;
    padding: 0.8rem 0.5rem;
    margin: 0 0.5rem;
    color: ${LINK_COLOR};
  }

  .dropdown {
    padding: 0.5rem;
  }
  .dropdown li a {
    margin: 0;    
    padding: 0.5rem;
  }

  li:hover {
    cursor: pointer;
  }

  ul li div ul {
    background-color: ${HEADER_COLOR};
    filter: brightness(0.9);
    min-width: 5rem;
    width: 120px;
    position: absolute;
    left: 0;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.55) 0 2px 8px -1px;
    opacity: 0;
    display: none;
    
    .pointer {
      height: 12px;
      width: 12px;
      position: absolute;
      background-color: ${HEADER_COLOR};
      top: -5px;
      transform: rotate(45deg);
      border-radius: 4px;
      left: 20px;
    }
  }

  ul li:hover > div ul,
  ul li div:hover,
  .dropdown:hover {
    opacity: 1;
    display: block;    
    z-index: 5;  
  }

  .dropdown:hover {
    li {
      transition: ease 2s;
      margin: 2px 0;
    }    
  }

  ul li ul li {
    clear: both;
    width: 100%;
  }
`

const Button = styled.button`
  &:focus {
    outline: none !important;
  }
  @media only screen and (min-width: 981px) {
    display: none;
  }  
  @media all and (max-width: 400px) {
    position: absolute;
    top: 8px;
    left: 0;
  }
`

const HamburgerList = styled.div<HeaderContainerProps>`
  transition: all 0.5s ease;
  width: 300px;
  background-color: ${HEADER_COLOR};
  position: absolute;
  top: ${HEADER_HEIGHT};
  z-index: 2000;
  right: 0;
  transform: translateY(0);
  height: ${(props) => (props.open ? "470px" : 0)};
  opacity: ${(props) => (props.open ? 1 : 0)};
  padding: 10px;
  .drop-down {
    padding: 10px 40px;
    a {
      color: #d8d8d8;
      display: block;
      text-decoration: none;
    }
  }
  .link-container {
    display: flex;
    justify-content: space-between;
    padding: 10px 20px;
    a {
      color: #fff;
      display: block;
      text-decoration: none;
    }
  }
`

const Header = () => {
  const data = useStaticQuery(graphql`
    query {
      logo: file(
        relativePath: { eq: "Mavela-Logo-ClearManyoni-Website-Logo.png" }
      ) {
        childImageSharp {
          fixed(width: 240) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)
  const [open, setOpen] = useState(false)
  const [show, setShow] = useState(true)
  const [scrollPos, setScrollPos] = useState(0)

  // useEffect(() => {
  //   function handleScroll(){
  //     const t = document.body.getBoundingClientRect().top;
  //     setShow(document.body.getBoundingClientRect().top > scrollPos);
  //     setScrollPos(document.body.getBoundingClientRect().top);
  //   }
  //   window.addEventListener("scroll", handleScroll);
  //   return () => { window.removeEventListener("scroll", handleScroll)}
  // }, [setScrollPos, setShow, scrollPos])

  return (
    <>
      <HeaderContainer className={show ? "show" : "hide"} open={open}>
        <HeaderContact>
          <HeaderContent>
            <ContactDetails style={{marginLeft:"30px"}}>
              <ul>
                <li className="mobile-hide">
                  <p><a href="mailto:reservations@mavela.co.za">reservations@mavela.co.za</a></p>
                </li>
                <li className="mobile-hide">
                  <p><a href="tel:+27359400996">+27 (0)35 940 0996</a></p>
                </li>
                <li className="mobile-hide">
                  <p><a href="tel:+27723476438">+27 (0)72 347 6438</a></p>
                </li>
                <li className="mobile-show">
                  <p><a href="tel:+273476438"><span class="material-icons">call</span>Call Us</a></p>
                </li>
                <span>|</span>
                <li className="mobile-show">
                  <p><a href="mailto:reservations@mavela.co.za"><span class="material-icons">email</span>Email Us</a></p>
                </li>
              </ul>
            </ContactDetails>
          </HeaderContent>          
        </HeaderContact>
        <HeaderMain>
          <HeaderContent>
            <HeaderLogo>
                <a href="/">
                  <Img
                    imgStyle={{ top: "5px" }}
                    fixed={data.logo.childImageSharp.fixed}
                    alt="Mavela game lodge"
                  />
                </a>
              </HeaderLogo>
              <HeaderMenu>
                <LinkWrapper>
                  <nav role="navigation">
                    <ul className="link-container">
                      <li>
                        <Link to="/">Home</Link>
                      </li>
                      <li>
                        <Link to="/game-lodge">About Us</Link>
                        <div>
                          <ul className="dropdown">
                            <div className="pointer"></div>
                            <li>
                              <Link to="/game-lodge">The Lodge</Link>
                            </li>
                            <li>
                              <Link to="/gallery">Gallery</Link>
                            </li>
                            <li>
                              <Link to="/game-lodge#Reserve">The Reserve</Link>
                            </li>
                          </ul>
                        </div>
                      </li>
                      <li>
                        <Link to="/luxury-safari-tents">Accommodation</Link>
                      </li>
                      <li>
                        <Link to="/activities">Activities</Link>
                      </li>
                      <li>
                        <Link to="/rates">Rates</Link>
                        <div>
                          <ul className="dropdown">
                            <div className="pointer"></div>
                            <li>
                              <Link to="/rates">Rates</Link>
                            </li>
                            <li>
                              <Link to="/special-rates">Specials</Link>
                            </li>
                          </ul>
                        </div>
                      </li>
                      <li>
                        <Link to="/latest-news">Latest News</Link>
                      </li>                    
                      <li>
                        <Link to="/contact-us">Contact Us</Link>
                      </li>                  
                    </ul>
                  </nav>                
                </LinkWrapper>
                <div className="mobile-hide">
                  <ActionButton style={{ margin: "10px" }}>
                    <a
                      href="https://www.nightsbridge.co.za/bridge/book?bbid=16200"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      Check Availability
                    </a>
                  </ActionButton>
                </div>
              
                <Button
                  onClick={() => setOpen(!open)}
                  className={`hamburger hamburger--slider ${open ? "is-active" : ""}`}
                >
                  <span className="hamburger-box">
                    <span className="hamburger-inner"></span>
                  </span>
                </Button>

              </HeaderMenu>
          </HeaderContent>
        </HeaderMain>
      </HeaderContainer>
      <HamburgerList open={open}>
        {open && (
          <>
            <div className="link-container">
              <Link to="/">Home</Link>
            </div>
            <div>
              <div className="link-container">
                <Link to="/game-lodge">About Us</Link>
                <AiOutlinePlus color="#fff" />
              </div>
              <div className="drop-down">
                <Link to="/game-lodge">The Lodge</Link>
              </div>
              <div className="drop-down">
                <Link to="/gallery">Gallery</Link>
              </div>
              <div className="drop-down">
                <Link to="/game-lodge#reserve">The Reserve</Link>
              </div>
            </div>
            <div className="link-container">
              <Link to="/luxury-safari-tents">Accommodation</Link>
            </div>
            <div className="link-container">
              <Link to="/activities">Activities</Link>
            </div>
            <div>
              <div className="link-container">
                <Link to="/rates">Rates</Link>
                <AiOutlinePlus color="#fff" />
              </div>
              <div className="drop-down">
                <Link to="/rates">Rates</Link>
              </div>
              <div className="drop-down">
                <Link to="/special-rates">Specials</Link>
              </div>
            </div>
            <div className="link-container">
              <Link to="/latest-news">Latest News</Link>
            </div>
            <div className="link-container">
              <Link to="/contact-us">Contact Us</Link>
            </div>
            
          </>
        )}
      </HamburgerList>

    </>
  )
}

export default Header
