import React from "react"
import Img from "gatsby-image"
import { Row, TextCenter } from "./General"
import { useStaticQuery, graphql, Link } from "gatsby"
import styled from "styled-components"

const Wrapper = styled.div`
  p, a {
    color: #B4B4B4;
    text-decoration: none;
  }
`

const FooterMain = styled.div`
  display: flex;
  background-color: #323232;
  padding: 40px 20px;  
  font-size: 15px;
  font-family: 'Playfair Display';
  input.button {
    min-width: unset;
    border: none;
    border-radius: 10px;
    font-family: 'Open Sans', sans-serif;
    color: white;
    background-color: #aba17b;
    font-size: 16px;
    text-transform: uppercase;
    line-height: 1.7em;
    padding: 1em 2em;
    cursor: pointer;
    &:hover {
      background-color: #131313;
      ::after {
        margin-left: 0.5em;
      }
    }
  }
  input {
    min-width: 300px;
  }
  @media all and (max-width: 400px) {
    input {
      min-width: 200px;
    }
  }
`

const ColumnsWrapper = styled.div`
  width: 100%;
  max-width: 1600px;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;  
  justify-content: space-between;
  >div {
    min-width: fit-content;
    display: block;    
    @media all and (max-width: 1220px) {
      margin: 20px;
      text-align: center;
    }
  }
  @media all and (max-width: 1600px) {
    justify-content: space-evenly;
  }
`

const Footer1 = styled.div`
  @media all and (max-width: 1220px) {
    width: 100%;
    display: flex !important;
    flex-direction: column;
    align-items: center;
  }
`

const FooterLogo = styled.div`  
  width: 290px;
`

const Footer2 = styled.div`
  margin: 0 40px;
`
const Footer3 = styled.div`
  h3 {
    font-family: "Open Sans", Arial, sans-serif;
    color: white;    
    font-weight: 200;
    margin-top: 10px;
    margin-bottom: 30px;
  }
`

const Copyright = styled.div`
  background-color: #131313;
  color: white;
  font-size: 13px;
  padding: 1rem;
`

const Footer = () => {
  const data = useStaticQuery(graphql`
    query {
      faceBook: file(relativePath: { eq: "facebook-logo-50x50.png" }) {
        childImageSharp {
          fixed(width: 32) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      tripadvisor: file(relativePath: { eq: "Tripadvisor-1.png" }) {
        childImageSharp {
          fixed(width: 32) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      instagram: file(relativePath: { eq: "instagram-50x50.png" }) {
        childImageSharp {
          fixed(width: 32) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      twitter: file(relativePath: { eq: "twitterlogo-50x50.png" }) {
        childImageSharp {
          fixed(width: 36) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      youtube: file(relativePath: { eq: "youtubelogo-50x50.png" }) {
        childImageSharp {
          fixed(width: 36) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      logo: file(
        relativePath: { eq: "Mavela-Logo-ClearManyoni-Website-Logo.png" }
      ) {
        childImageSharp {
          fixed(width: 290) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
    
  `)
  return (
    <Wrapper>
      <FooterMain>
        <ColumnsWrapper>
          <Footer1>
            <FooterLogo>
              <a href="/">
                <Img
                  fixed={data.logo.childImageSharp.fixed}
                  alt="Mavela game lodge"
                />
              </a>
            </FooterLogo>
            <a target="_blank" href="https://www.google.com/maps/place/Manyoni+Private+Game+Reserve,+North+Gate/@-27.6406709,32.0212571,15z/data=!4m5!3m4!1s0x0:0xaea49f33c98e5f7e!8m2!3d-27.6406709!4d32.0212571">
            <address style={{fontStyle:"normal"}}>
              <p>Manyoni Private Game Reserve</p>
              <p>Mkuze, 3965, Kwazulu-Natal</p>
              <p>South Africa</p>
            </address>
            </a>
          </Footer1>
          <Footer2>
            <div style={{maxWidth:"500px" , margin:"0 auto"}}>
              <a
                href="http://www.facebook.com/mavelagamelodge"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Img
                  fixed={data.faceBook.childImageSharp.fixed}
                  style={{ margin: "8px" }}
                ></Img>
              </a>
              <a
                href="https://www.tripadvisor.co.za/Hotel_Review-g13394008-d3840632-Reviews-Mavela_Game_Lodge-Manyoni_Private_Game_Reserve_KwaZulu_Natal.html"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Img
                  fixed={data.tripadvisor.childImageSharp.fixed}
                  style={{ margin: "8px" }}
                ></Img>
              </a>
              <a
                href="https://www.instagram.com/mavela_game_lodge/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Img
                  fixed={data.instagram.childImageSharp.fixed}
                  style={{ margin: "8px" }}
                ></Img>
              </a>
              <a
                href="https://www.youtube.com/channel/UCsfUq9i6_T5Dajf0VxoUaag"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Img
                  fixed={data.youtube.childImageSharp.fixed}
                  style={{ margin: "8px" }}
                ></Img>
              </a>
              <a
                href="https://twitter.com/Mavela_Lodge"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Img
                  fixed={data.twitter.childImageSharp.fixed}
                  style={{ margin: "8px" }}
                ></Img>
              </a>
            </div>
            <div style={{maxWidth:"500px" , margin:"30px auto" }}>
              <a className="link" href="mailto:reservations@mavela.co.za">
                reservations@mavela.co.za
              </a>
              <p>
                Tel:{" "}
                <a className="link" href="tel:+27359400996">
                  +27 (0)35 940 0996
                </a>{" "}
                or Mobile/Whatsapp{" "}
                <a className="link" href="tel:+27723476438">
                  +27 (0)72 347 6438
                </a>
              </p>
              <div style={{marginTop:"30px"}}>
                <Link to="/privacy-policy">Privacy Policy</Link>
                <a href="Booking_and_Cancellation_Policy.pdf" rel="noopener noreferrer" target="_blank" style={{display:"block"}}>
                Reservations & Cancellations
              </a>
              </div>      
            </div>
          </Footer2>
          <Footer3>
            <h3>
              KEEP UP TO DATE WITH SPECIALS AND MORE
            </h3>
            <div id="mc_embed_signup">
              <form
                action="https://mavela.us17.list-manage.com/subscribe/post?u=cd994fece8b828ee4a67249a6&amp;id=c8c98f6d5f"
                method="post"
                id="mc-embedded-subscribe-form"
                name="mc-embedded-subscribe-form"
                target="_blank"
                noValidate
              >
                <div
                  id="mc_embed_signup_scroll"
                  style={{ width: "100%" , marginBottom:"1rem" }}
                >
                  <input
                    style={{padding:"1rem"}}
                    type="email"
                    name="EMAIL"
                    id="mce-EMAIL"
                    placeholder="Email address"
                    required
                  />
                  <div
                    style={{ position: "absolute", left: "-5000px" }}
                    aria-hidden="true"
                  >
                    <input
                      type="text"
                      name="b_cd994fece8b828ee4a67249a6_c8c98f6d5f"
                      tabIndex="-1"
                    />
                  </div>
                  <div style={{marginTop:"20px"}}>
                    <input
                      className="button"
                      type="submit"
                      value="Subscribe"
                      name="subscribe"
                      id="mc-embedded-subscribe"
                    />
                  </div>
                </div>
              </form>
            </div>
            
          </Footer3>
        </ColumnsWrapper>
      </FooterMain>
      <Copyright>
        <TextCenter>
          Copyright @ Mavela Game Lodge. All rights reserved 2021
        </TextCenter>
      </Copyright>

    </Wrapper>
    
  )
}

export default Footer
