import React from "react"
import {Helmet} from "react-helmet";
import Header from "./Header"
import Footer from "./Footer"
import "../sass/global.scss"
import Analytics from "./Analytics"

if (typeof window !== "undefined") {
  // eslint-disable-next-line global-require
  require("smooth-scroll")('a[href*="#"]')
}

const PageLayout = ({ children }: any) => {
  return (
    <>
      {/* <Analytics /> */}
      <Helmet>
        <script type="text/javascript" src="https://www.bugherd.com/sidebarv2.js?apikey=falvzstacyqx3jyidmxddg" async="true"></script>
        <link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet"></link>
      </Helmet>

      <Header></Header>
      {children}
      <Footer></Footer>
    </>
  )
}

export { PageLayout }
