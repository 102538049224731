import styled from "styled-components"

interface TextCenterProps {
  pa?: string
  wi?: string
}

const TextCenter = styled.div<TextCenterProps>`
  text-align: center;
  padding: ${props => props.pa};
  width: ${props => props.wi};
`

interface RowProps {
  jc?: string
  ai?: string
  pa?: string
  he?: string
  fw?: string
  fd?: string
}

const Row = styled.div<RowProps>`
  display: flex;
  justify-content: ${props => props.jc};
  align-items: ${props => props.ai};
  padding: ${props => props.pa};
  height: ${props => props.he};
  flex-wrap: ${props => props.fw};
  flex-direction: ${props => props.fd};
`

export { TextCenter, Row }
